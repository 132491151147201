.bottom-parent {
  position: relative;
  left: 0;
  bottom: 0;
  right: 0;
  margin-top: auto;
  width: 100%;
  background-color: #de4620;
  border-radius: 5px;
  padding: 20px 0;
}

.mantralekhanFooter p{
  color: white !important;
  text-decoration: none;
}

.mantralekhanFooter p>a{
  color: white;
  text-decoration: none;
}

.mantralekhanFooter p>a:hover {
  color: white;
  text-decoration: underline;
}
