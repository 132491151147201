
.allusers-main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f8f9fa;
  }

  tr>th{
    background-color: #de4620 !important;
    color: #fff !important;
  }

  .table{
    margin-bottom: 0;
  }

  .table-box{
    flex: 1;
    flex-grow: 1;
    display: flex;
    padding: 0px 30px;
    padding:0;
    overflow-x: auto; 
    margin:0;
    margin-top: 5%;
  }

  .table-container{
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    padding:0; margin:0;
    height: fit-content;
  }

  tr:nth-of-type(even) {
    background-color: #f3f3f3 !important;
  }
  
  @media (max-width: 600px) {
    th, td {
      padding: 8px 10px;
      font-size: 0.9em;
    }
    
    table {
      font-size: 0.9em;
    }
    
    table caption {
      font-size: 1.2em;
    }
  } 