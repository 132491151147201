.login-div{
    display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f2f2f2;
    flex: 1;
  }
  
  /* Basic styles for the form */
  .login-form {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
  }
  
  /* Styles for form groups */
  .form-group {
    margin-bottom: 15px;
  }
  
  /* Styles for labels */
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  /* Styles for input fields */
  .form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  /* Styles for the submit button */
  .login-button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #de4620;
    color: white;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  /* Change background color on button hover */
  .login-button:hover {
    background-color: rgba(222, 70, 32, 0.9);
  }
  
  /* Media query for smaller screens */
  @media (max-width: 600px) {
    .login-form {
      padding: 15px;
      box-shadow: none;
    }
  
    .form-group input {
      padding: 8px;
    }
  
    .login-button {
      padding: 8px;
      font-size: 14px;
    }
  }
  